import React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const InteriorDesign = () => (
  <StaticQuery
    render={data => (
      <Layout>
        <Img fluid={data.interiorDesignFluid.childImageSharp.fluid} />
        <h1 id="whoweare">
          <strong>Who we are</strong>
        </h1>
        <p>
          <em>DUATO</em> Interior Design Projects is a branch of <em>DUATO</em>{' '}
          specialized in bringing out the space with a combination of{' '}
          <strong>CREATIVITY</strong> and <strong>FUNCTIONALITY</strong>.
          Whether if we create a showroom, a shop, a stand for a fair or even a
          entire private house, we know how to satisfy your likes and needs with
          unique and creative designs that reply to each practical and
          functional exigency. Our team is mixed whit Chinese and Foreigners to
          make sure that we bring the best of each world in terms of
          understanding client’s needs and adding the best know-how in each
          situation. Composed by designers, engineers and other professionals it
          make sure that we cover a great knowledge by doing things right and
          special for each project: from the creation moment until the
          construction finalization and decoration!
        </p>

        <h1 id="howdowework">
          <strong>How do we work</strong>
        </h1>

        <p>
          Our process is simple but effective:{' '}
          <strong>
            OUR TEAM CONTACT WHIT CLIENT ANALYZE CLIENT’S NEEDS (EUROPEAN
            DESIGNERS
          </strong>{' '}
          follow up the creation process with Chinese team in China in order to
          provide the <em>BEST SOLUTIONS</em> by understanding well the
          Chinese's clients needs and correspond with the European know-how
          adapted to them. This is without any doubt our{' '}
          <strong>COMPETITIVE ADVANTAGE). WE MAKE YOUR IDEAS REALITY!</strong>.
        </p>

        <h1 id="whatwedo">
          <strong>What we do</strong>
        </h1>

        <p>
          <em>DUATO</em> Interior Design Projects provides creative designs that
          respond to the customers needs under the best construction. The whole
          supported by: Sketches Renders 3D Furniture Lighting Study for the
          Best Effect
        </p>
      </Layout>
    )}
    query={graphql`
      query {
        interiorDesignFluid: file(
          relativePath: { eq: "slider/head-pic03.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
  />
)
export default InteriorDesign
